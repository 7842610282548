export const PROJECT_LIST = [
  {
    title: 'WPP House @SXSW',
    imgSrc: require('../ProjectPages/assets/WPPHouse/vortex.mov'),
    timeStart: 0,
    pgSrc: '/wpp-house',
    description: 'Creative Coding & Web Design',
  },
  {
    title: 'Lucky Tiger',
    imgSrc: require('../ProjectPages/assets/LuckyTiger/cny_su.mov'),
    timeStart: 1,
    pgSrc: '/lucky-tiger',
    description: 'Web Dev & Design',
  },
  {
    title: 'Signature Spectrum',
    imgSrc: require('../ProjectPages/assets/SignatureSpectrum/signature_mainVideo.mp4'),
    timeStart: 0,
    pgSrc: '/signature-spectrum',
    description: 'Creative Coding',
  },
  {
    title: '2022 Demo Reel',
    imgSrc: require('../ProjectPages/assets/DemoReel/2022MotionReel.mp4'),
    pgSrc: '/2022-demo-reel',
    timeStart: 13,
    timeEnd: 23,
    description: 'Motion Design',
  },
];
